<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}

.icon {
  float: right;
}

.card-header {
  cursor: pointer;
}

.card-header span {
  font-weight: 600;
}
.card-body {
  padding: 0;
}
.show-card {
  overflow: hidden;
  transition: 0.3s;
}
.card-header {
  padding: 1rem 0.25rem !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="full-load" v-if="generateInvoice">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by invoice no"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="searchInvoice()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="searchInvoice()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-3 col-sm-2 pr-0">
          <div class="form-group">
            <select v-model="company_id" name="" class="form-control" id="">
              <option value="all">All Company</option>
              <option
                v-for="(citem, index) in getMasterCompanies"
                :key="index"
                :value="citem.id"
              >
                {{ citem.company_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 pl-2 pr-0">
          <div class="form-group">
            <input
              type="date"
              v-model="date_from"
              class="form-control"
              name=""
              id=""
            />
          </div>
        </div>

        <div class="col-md-2 col-sm-2 pl-2 pr-0">
          <div class="form-group">
            <input
              type="date"
              v-model="date_to"ss
              class="form-control"
              name=""
              id=""
            />
          </div>
        </div>
        <div
          class="col-md-1 col-sm-1"
          style="display: flex; flex-wrap: nowrap;align-items: center; top: -1rem;"
        >
          <button class="btn btn-outline-info btn-sm" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div> -->

      <div v-if="reports.length > 0 && !getLoadingData">
        <div class="card mt-2" v-for="item in reports" :key="item.id">
          <div class="card-header" style="background-color: #e6e6ff;">
            <!-- <div
              style="display:flex; justify-content: space-evenly; align-items: center;"
            > -->
            <div style="display:flex;">
              <div
                @click.prevent="toggleExpand(item)"
                style="width:80%; padding:5px; margin-left:10px;"
              >
                <span>Invoice # {{ item.invoice_no }} </span>
                <div class="icon">
                  <i class="fas fa-arrow-down" v-if="!item.isExpand"></i>
                  <i class="fas fa-arrow-up" v-if="item.isExpand"></i>
                </div>
              </div>
              <!-- <div>
                <button
                  class="btn btn-sm btn-light-success font-weight-bolder font-size-sm"
                  @click="reprintInvoice(item.invoice_no)"
                >
                  Print
                </button>
              </div> -->
            </div>
          </div>

          <div
            class="card-body show-card"
            :ref="'content' + item.id"
            :style="{ display: `${!item.isExpand ? 'none' : 'block'}` }"
          >
            <div class="card-content p-2">
              <div class="row">
                <div class="col-md-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th class="text-left">Company name</th>
                        <th>Applicant Name</th>
                        <th>Service Name</th>
                        <th>Location name</th>
                        <th>Service Status</th>
                        <th>Payment Status</th>
                        <th>Scheduled Date</th>
                        <th class="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(apps, index) in item.content" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ apps.company_name }}
                        </td>
                        <td>{{ apps.applicant }}</td>
                        <td>{{ apps.service }}</td>
                        <td>
                          {{ apps.client }}
                        </td>
                        <td>{{ apps.service_status }}</td>
                        <td>{{ apps.payment_status }}</td>

                        <td v-if="apps.enroll_date && apps.enroll_date != ''">
                          {{ dateFormat(apps.enroll_date) }}
                        </td>
                        <td v-else-if="apps.schedule_date == null">
                          N/A
                        </td>
                        <td v-else-if="apps.schedule_date != ''">
                          {{ dateFormat(apps.schedule_date) }}
                        </td>

                        <td class="text-right" v-if="apps.amount">
                          $ {{ apps.amount | toFixedTwoDigit }}
                        </td>
                        <td class="text-right" v-else>$ 0.00</td>
                      </tr>
                      <tr
                        class="font-weight-bolder border-bottom-0 font-size-lg"
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>

                        <td class="text-right">${{ item.total }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row" v-if="reports.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th class="text-left">Company name</th>
                <th>Applicant Name</th>
                <th>Service Name</th>
                <th>Location name</th>
                <th>Service Status</th>
                <th>Payment Status</th>
                <th>Invoice No.</th>
                <th>Scheduled Date</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in reports" :key="index">
                <td>
                  {{ item.company_name }}
                </td>
                <td>{{ item.applicant }}</td>
                <td>{{ item.service }}</td>
                <td>
                  {{ item.client }}
                </td>
                <td>{{ item.service_status }}</td>
                <td>{{ item.payment_status }}</td>
                <td>
                  {{
                    item.company_invoice_no != ""
                      ? item.company_invoice_no
                      : "-"
                  }}
                </td>

                <td v-if="item.enroll_date && item.enroll_date != ''">
                  {{ dateFormat(item.enroll_date) }}
                </td>
                <td v-else-if="item.schedule_date == null">
                  N/A
                </td>
                <td v-else-if="item.schedule_date != ''">
                  {{ dateFormat(item.schedule_date) }}
                </td>

                <td class="text-right" v-if="item.amount">
                  $ {{ item.amount | toFixedTwoDigit }}
                </td>
                <td class="text-right" v-else>$ 0.00</td>
              </tr>
              <tr>
                <td :colspan="8">
                  <b>Grand Total</b>
                </td>
                <td class="text-right" style="width: 10%;">
                  <b>
                    $
                    {{ grand_total | toFixedTwoDigit }}</b
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <div class="row" v-if="reports.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>Invoice Not Found</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_COMPANY_INVOICE_APP_DETAIL,
  REPRINT_COMPANY_INVOICE,
  FETCH_MASTER_COMPANIES
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["currentUser", "getMasterCompanies"]),
    grand_total: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.amount;
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      generateInvoice: false,
      loadingMessage: "",
      search_text: "",
      company: "",
      company_id: "all",
      company_name: "",
      search: {
        company: "all"
      },
      date_from: this.$moment(new Date())
        .add(-7, "days")
        .format("YYYY-MM-DD"),
      date_to: this.$moment(new Date()).format("YYYY-MM-DD"),
      getLoadingData: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Invoice",
          route: ""
        },
        {
          id: 2,
          title: "Company Invoice",
          route: ""
        }
      ],
      reports: []
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_MASTER_COMPANIES);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.date_from = this.$moment(new Date())
      .add(-7, "days")
      .format("YYYY-MM-DD");
    this.date_to = this.$moment(new Date()).format("YYYY-MM-DD");
  },

  methods: {
    toggleExpand(item) {
      item.isExpand = !item.isExpand;
    },

    searchInvoice() {
      this.selectAll = false;
      this.getLoadingData = true;

      var search = {
        invoice_no: this.search_text,
        company_id: this.company_id,
        date_from: this.date_from,
        date_to: this.date_to
      };
      this.$store
        .dispatch(FETCH_COMPANY_INVOICE_APP_DETAIL, search)
        .then(res => {
          this.reports = [];
          this.getLoadingData = false;
          res.data.map(data => {
            this.reports.push({
              id: data.id,
              content: data.appointment_data,
              isExpand: false,
              invoice_no: data.invoice_no,
              computedHeight: 0,
              qty: data.qty,
              total: data.total
            });
          });
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },

    advSearch() {
      this.getLoadingData = true;

      this.search_text = "";

      var search = {
        invoice_no: this.search_text,
        company_id: this.company_id,
        date_from: this.date_from,
        date_to: this.date_to
      };
      this.$store
        .dispatch(FETCH_COMPANY_INVOICE_APP_DETAIL, search)
        .then(res => {
          this.reports = [];
          this.getLoadingData = false;
          res.data.map(data => {
            this.reports.push({
              id: data.id,
              content: data.appointment_data,
              isExpand: false,
              invoice_no: data.invoice_no,
              computedHeight: 0,
              qty: data.qty,
              total: data.total
            });
          });
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },

    reprintInvoice(invoice_no) {
      this.generateInvoice = true;
      this.loadingMessage = "Saving Invoice ...";
      var data_ = {
        invoice_no: invoice_no
      };
      this.$store.dispatch(REPRINT_COMPANY_INVOICE, data_).then(data => {
        const contentDisposition = data.headers["content-disposition"];
        let fileName = "unknown";
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        const url = window.URL.createObjectURL(
          new Blob([data.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
      this.generateInvoice = false;
      this.loadingMessage = "";
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },

    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
